import {
  ArrowButton,
  ButtonPosition,
} from '@features/shared/components/arrow-button';
import classNames from 'classnames';
import { ComponentProps } from 'react';
import Slider from 'react-slick';
import CardDetail from 'src/features/shared/components/card-detail';
import styles from './index.module.scss';

interface ICardSliderProps {
  data: {
    name: string;
    role: string;
    image: string;
    slug: string;
  }[];
  isPanel?: boolean;
}

const SliderArrowButton = ({
  onClick,
  isPrev,
}: ComponentProps<typeof ArrowButton>) => (
  <ArrowButton
    place={ButtonPosition.AbsoluteBottom}
    isPrev={isPrev}
    onClick={onClick}
  />
);

const settings = {
  infinite: false,
  accessibility: true,
  speed: 500,
  slidesToShow: 3.7,
  slidesToScroll: 1,
  centerMode: false,
  initialSlide: 0,
  arrows: true,
  responsive: [
    {
      breakpoint: 1439,
      settings: {
        slidesToShow: 3.7,
      },
    },
    {
      breakpoint: 1023,
      settings: {
        slidesToShow: 3.2,
      },
    },
    {
      breakpoint: 850,
      settings: {
        slidesToShow: 2.7,
      },
    },
    {
      breakpoint: 770,
      settings: {
        slidesToShow: 2.2,
      },
    },
    {
      breakpoint: 650,
      settings: {
        slidesToShow: 1.7,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1.2,
      },
    },
  ],
  nextArrow: <SliderArrowButton />,
  prevArrow: <SliderArrowButton isPrev />,
};

const panelSettings = {
  ...settings,
  slidesToShow: 1.7,
  responsive: [
    {
      breakpoint: 1439,
      settings: {
        slidesToShow: 1.7,
      },
    },
    {
      breakpoint: 770,
      settings: {
        slidesToShow: 2.2,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1.2,
        centerPadding: '16px',
      },
    },
  ],
};

export function CardSlider({ data, isPanel }: ICardSliderProps) {
  const sliderSettings = isPanel ? panelSettings : settings;

  return (
    <Slider
      {...sliderSettings}
      className={classNames(styles.container, {
        [styles.isPanel]: isPanel,
      })}
    >
      {data.map((item) => (
        <CardDetail
          {...item}
          key={item.name}
          title={item.name}
          subtitle={item.role}
          isPanel={isPanel}
        />
      ))}
    </Slider>
  );
}
