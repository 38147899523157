import { useIntl } from '@domains/i18n';
import { Anchor } from '@features/club-page/components/club-details/anchor-links/anchor';
import { DescriptionTypography } from '@features/shared/components/description-typography';
import {
  Col,
  Container,
  Row,
  Section,
} from '@features/shared/components/layout';
import { ISpecialist } from '@features/specialists/types/ISpecialist';
import useBreakpoints from '@utils/hooks/use-breakpoints';
import { EntryFields } from 'contentful';
import CardSlider from 'src/features/club-page/components/specialists/card-slider';
import { IClub } from 'src/features/club-page/types/IClub';
import { ClubPageSectionHeading } from 'src/features/shared/components/typography';
import { useRichTextRenderer } from 'src/features/shared/hooks/useRichTextRenderer';
import { getSlugFromText } from 'src/features/shared/utils/get-slug-from-text';
import styles from './index.module.scss';

interface ISpecialistsProps {
  anchorLinkTitle: string;
  title?: string;
  description?: EntryFields.RichText;
  specialists: ISpecialist[];
  isPanel?: boolean;
  club: IClub;
}

export function Specialists({
  anchorLinkTitle,
  title,
  description,
  specialists,
  isPanel,
  club,
}: ISpecialistsProps) {
  const { formatMessage } = useIntl();
  const { renderRichText } = useRichTextRenderer();
  const { currentBreakpoint, breakpoints } = useBreakpoints();
  const isMobile = currentBreakpoint === breakpoints.xs.name;

  if (!specialists || specialists.length === 0) {
    return null;
  }

  return (
    <Section>
      <Anchor title={anchorLinkTitle} />
      <Container className={styles.content} forceMobileView={isPanel}>
        <Row>
          <Col columns="xs:12">
            <ClubPageSectionHeading>
              {title || formatMessage('club-detail.specialists.title')}
            </ClubPageSectionHeading>
          </Col>
          <Col
            className={styles.description}
            columns={`xs:12 ${!isPanel && 'md:7'}`}
          >
            <DescriptionTypography>
              {description ? (
                renderRichText(description)
              ) : (
                <p>
                  {formatMessage(
                    'club-detail.specialists.default-description',
                    { club: club.name },
                  )}
                </p>
              )}
            </DescriptionTypography>
          </Col>
        </Row>
      </Container>

      <Container className={isMobile || isPanel ? styles.container : ''}>
        <Col columns="xs:12" className={styles.sliderContainer}>
          {specialists && (
            <CardSlider
              data={specialists.map((specialist) => ({
                ...specialist,
                slug: `/specialisten/${getSlugFromText(specialist.role)}/${
                  specialist.slug
                }`,
              }))}
              isPanel={isPanel}
            />
          )}
        </Col>
      </Container>
    </Section>
  );
}
