import { Link } from '@features/shared/components/link';
import classNames from 'classnames';
import { Image } from 'src/features/shared/components/image';
import { Body, FontColor, H3 } from 'src/features/shared/components/typography';
import { GA4_EVENTS, pushGA4Event } from 'src/features/shared/utils/ga4';
import styles from './index.module.scss';

export interface ICardDetailProps {
  title: string;
  subtitle?: string;
  image?: string;
  slug: string;
  isPanel?: boolean;
  className?: string;
  eventName?: string;
}

export function CardDetail({
  title,
  subtitle,
  image,
  slug,
  isPanel,
  eventName,
  className: givenClassName,
}: ICardDetailProps) {
  const className = classNames(
    styles.wrapper,
    isPanel && styles.isPanel,
    givenClassName,
  );

  const onClick = () => {
    if (eventName) {
      pushGA4Event(GA4_EVENTS.trackEvent, { event_name: eventName });
    }
  };

  const children = (
    <>
      {image && (
        <Image
          className={styles.backgroundImage}
          image={{ src: image, alt: title }}
        />
      )}
      <div className={styles.container}>
        <div className={styles.titles}>
          <H3 color={FontColor.Neutral06}>{title}</H3>
          <Body color={FontColor.Neutral06}>{subtitle}</Body>
        </div>
      </div>
    </>
  );

  return isPanel ? (
    <div className={className}>{children}</div>
  ) : (
    <Link onClick={onClick} className={className} href={slug} isHtmlTag>
      {children}
    </Link>
  );
}
